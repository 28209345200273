import React, { useState } from "react";

import {
  Container,
  Image,
  Text,
  ButtonAlt,
  Icon,
  AnimateIn,
  Button,
} from "@atoms";
import { AppLink } from "@base";
import { HeadingWIcon } from "@molecules";

const Milestones = ({ heading, copy, milestones, cutoff }) => {
  const [visible, setVisible] = useState(cutoff || 5);
  return (
    <div className="relative bg-gray-light py-12">
      <Container>
        <div className="max-w-2xl">
          <HeadingWIcon
            heading={heading}
            icon="sunrise"
            copy={copy}
            className="mb-4"
            small
          />
        </div>
      </Container>
      <div>
        <ul className="mt-12 lg:mt-20">
          {milestones.slice(0, visible).map((c, i) => {
            return (
              <AnimateIn>
                <li
                  className="relative p-4 pb-8 sm:p-6 sm:pb-12 md:pb-24"
                  key={c.uid}
                >
                  <Container variant="xs">
                    <div className="absolute top-0 bottom-0 border-l-2 border-white">
                      <div className="absolute top-0 left-0 -ml-3 mt-1">
                        <AnimateIn>
                          <Icon
                            name={
                              i === milestones.length - 1
                                ? "iconLakota"
                                : "milestoneStar"
                            }
                            className="-mt-px h-6 w-6 text-red"
                          />
                        </AnimateIn>
                      </div>
                    </div>
                    <div className="p-4 sm:p-6">
                      <Text variant="label" className="-mt-10 pt-px">
                        {c.date}
                      </Text>
                      <Text variant="h5" className="my-4 font-bold">
                        {c.heading}
                      </Text>
                      <Text variant="body--large-tight">{c.copy}</Text>
                    </div>
                  </Container>
                  <ul>
                    {c.entries &&
                      c.entries.map(e => {
                        if (e.type === "photo") {
                          return (
                            <li
                              key={e.uid}
                              className="-mx-4 mt-12 mb-3 sm:-mx-6"
                            >
                              <AnimateIn>
                                <Container className="z-10">
                                  <div className="shadow-xl">
                                    <Image image={e.image} />
                                  </div>
                                </Container>
                                <Container className="relative z-0">
                                  <div className="flex">
                                    <div className="ml-auto mr-4 max-w-2xl bg-white px-6 py-4 text-right shadow-xl sm:mr-6">
                                      <Text variant="body">{e.caption}</Text>
                                    </div>
                                  </div>
                                </Container>
                              </AnimateIn>
                            </li>
                          );
                        }
                        // quote
                        if (e.type === "quote_legacy") {
                          return (
                            <li
                              key={e.uid}
                              className="-mx-4 my-12 bg-brown text-white sm:-mx-6"
                            >
                              <AnimateIn>
                                <Container variant="sm">
                                  <div className="px-12 py-24 sm:pl-48 lg:p-32 lg:pl-48">
                                    <Text
                                      variant="body--xl-tight"
                                      className="text-white"
                                    >
                                      {e.quote}
                                    </Text>
                                    <Text
                                      variant="body"
                                      className="mt-6 text-right text-white"
                                    >
                                      {`–${e.citation}`}
                                    </Text>
                                  </div>
                                </Container>
                              </AnimateIn>
                            </li>
                          );
                        }
                        return (
                          <li key={e.uid}>
                            <Container variant="sm">
                              <AppLink
                                to={e.link}
                                className="group mb-6 ml-auto flex w-full max-w-2xl flex-wrap items-stretch bg-white text-left shadow-xl md:mb-10 md:mt-4 md:flex-nowrap"
                              >
                                {e.image && (
                                  <div
                                    className="relative w-full flex-shrink-0 sm:w-24 md:w-32 lg:w-48"
                                    style={{ minHeight: "150px" }}
                                  >
                                    <div className="absolute inset-0">
                                      <Image image={e.image} fill />
                                    </div>
                                  </div>
                                )}
                                <div className="w-full p-6">
                                  <div className="-mx-4 flex h-full flex-wrap items-center lg:flex-nowrap">
                                    <div className="w-full px-4">
                                      {e.label && (
                                        <Text
                                          variant="body--small-tight"
                                          className="mb-3 font-bold uppercase text-red"
                                        >
                                          {e.label}
                                        </Text>
                                      )}
                                      <Text variant="body">
                                        {e.body || e.bodyExpanded}
                                      </Text>
                                    </div>
                                    <div className="ml-auto w-auto max-w-xs flex-shrink-0 justify-center px-4">
                                      <ButtonAlt noIcon>{e.cta}</ButtonAlt>
                                    </div>
                                  </div>
                                </div>
                              </AppLink>
                            </Container>
                          </li>
                        );
                      })}
                  </ul>
                </li>
              </AnimateIn>
            );
          })}
        </ul>
        {visible <= (cutoff || 5) && (
          <div className="flex items-center justify-center p-6">
            <Button onClick={() => setVisible(999)}>Load More</Button>
          </div>
        )}
      </div>
    </div>
  );
};

Milestones.defaultProps = {};

export default Milestones;
